import React from "react";

import { Router, BrowserRouter, Link } from "react-router-dom";

function Footer() {
    return (
        <div className="container footerContainer">
            <div className="contact">
                <h1>Freiwillige Feuerwehr Lederdorn</h1>
                <h2>Hauptstraße 4, 93466 Lederdorn</h2>
            </div>
            <footer>
                <p style={{ color: "rgb(250,250,250,0.75)" }}>
                    © Copyright 1898 - {new Date().getFullYear()} Freiwillige
                    Feuerwehr Lederdorn - All rights reserved.{" "}
                    <Link
                        to="/impressum"
                        style={{
                            color: "rgb(250,250,250,0.75)",
                            float: "right",
                        }}
                    >
                        Impressum
                    </Link>
                </p>
            </footer>
        </div>
    );
}

export default Footer;
