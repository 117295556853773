import React from "react";

import { Link } from "react-router-dom";
import Header from "../components/Header";
import Image from "../img/image.jpeg";

function Home({ loading, setLoading }) {
  const [articles, setArticles] = React.useState([]);
  const [covidData, setCovidData] = React.useState(null);
  const [covidIncidenceChange, setCovidIncidenceChange] = React.useState(0);

  return (
    <div className="App">
      <Header loading={loading} setLoading={setLoading} bigHeader={true} />
      <div className="container">
        <h2 className="Title">Aktuelles:</h2>
        <div className="w-full text-white">
          <img className="max-w-full max-h-full w-auto mx-auto" src={Image} alt="Kesselfleischessen" />
        </div>

        {articles.map((article, i) => {
          return (
            <>
              <article>
                <h2 className="LatestTitle">{article.title}</h2>
                {article.feature_image && (
                  <img
                    draggable="false"
                    className="LatestPicture"
                    src={article.feature_image.replace(
                      "http://localhost:2368/",
                      "https://cms.feuerwehr-lederdorn.de/"
                    )}
                    alt=""
                  ></img>
                )}
                <div className="meta">
                  {" "}
                  {article.reading_time && (
                    <span>
                      {article.reading_time} Minute
                      {article.reading_time > 1 && <span>n</span>} Lesezeit
                    </span>
                  )}
                </div>
                <p
                  className="LatestText"
                  dangerouslySetInnerHTML={{
                    __html: article.excerpt,
                  }}
                ></p>

                <Link
                  draggable="false"
                  onClick={() => setLoading(true)}
                  className="button"
                  to={`/article/${article.id}`}
                >
                  Mehr erfahren
                </Link>
              </article>
            </>
          );
        })}
      </div>
    </div>
  );
}

export default Home;
