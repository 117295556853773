import React from "react";

import Logo from "../img/Logo.png";
import HeaderImage from "../img/Header.jpg";

import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { LazyLoadImage } from "react-lazy-load-image-component";

function Header({ loading, setLoading, bigHeader }) {
  const [navigationOpened, setNavigationOpened] = React.useState(false);

  React.useEffect(() => {
    if (navigationOpened) {
      document.body.classList.add("noscroll");
    } else {
      document.body.classList.remove("noscroll");
    }
  }, [navigationOpened]);

  return (
    <>
      <CSSTransition
        in={navigationOpened}
        unmountOnExit
        onExit={() => {}}
        onEnter={() => {}}
        timeout={500}
        classNames="nav-anim"
      >
        <div id="myNav" className="overlay open">
          <div className="container">
            <svg
              onClick={() => {
                setNavigationOpened(false);
              }}
              className="closebtn"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
          </div>
          <div className="overlay-content">
            <Link
              onClick={() => {
                setNavigationOpened(false);
              }}
              to="/"
            >
              Startseite
            </Link>
            <Link
              onClick={() => {
                setNavigationOpened(false);
                setLoading(true);
              }}
              to="/fahrzeuge"
            >
              Fahrzeuge
            </Link>
            <Link
              onClick={() => {
                setNavigationOpened(false);
                setLoading(true);
              }}
              to="/termine"
            >
              Termine
            </Link>
            <Link
              onClick={() => {
                setNavigationOpened(false);
                setLoading(true);
              }}
              to="/geschichte"
            >
              Geschichte
            </Link>
          </div>
        </div>
      </CSSTransition>
      <CSSTransition
        in={loading}
        unmountOnExit
        onExit={() => {}}
        onEnter={() => {}}
        timeout={500}
        classNames="nav-anim"
      >
        <div className="loader">
          <div className="loader-object"></div>
        </div>
      </CSSTransition>
      <div className="container header">
        <Link to="/">
          <div className="Logo">
            <img draggable="false" className="Logo" src={Logo}></img>
          </div>
        </Link>
        <a className="menuButton">
          <svg
            onClick={() => setNavigationOpened(true)}
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </a>

        {bigHeader && (
          <>
            <LazyLoadImage
              className="Header"
              draggable="false"
              src={HeaderImage}
            />
            <h1 className="HeaderText">
              Stütz&shy;punkt&shy;feuerwehr im Land&shy;kreis Cham.
            </h1>
            <svg
              className="downArrow bounce"
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="35"
              viewBox="0 0 24 24"
            >
              <path fill="none" d="M0 0h24v24H0V0z" />
              <path d="M11 5v11.17l-4.88-4.88c-.39-.39-1.03-.39-1.42 0-.39.39-.39 1.02 0 1.41l6.59 6.59c.39.39 1.02.39 1.41 0l6.59-6.59c.39-.39.39-1.02 0-1.41-.39-.39-1.02-.39-1.41 0L13 16.17V5c0-.55-.45-1-1-1s-1 .45-1 1z" />
            </svg>
          </>
        )}
      </div>
    </>
  );
}

export default Header;
